import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private http: HttpClient) {}

  validate(token: string): Observable<boolean> {
    return this.http.post<boolean>('/pub/validateToken', token).pipe(
      tap(res => console.log(res)),
      catchError(this.handleError(false))
    );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.log('Validation failed ' + error);
      return of(result as T);
    };
  }
}

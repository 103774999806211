export class OnlineBookingWindow {
  id: string;
  resourceId: string;
  dayOfWeek: string;
  startTime: string;
  endTime: string;

  constructor(json: any) {
    this.id = json.id;
    this.resourceId = json.resourceId;
    this.dayOfWeek = json.dayOfWeek;
    this.startTime = json.startTime;
    this.endTime = json.endTime;
  }
}

export class OnlineBookingSchedulingWindow {
  id: string;
  date: string;
  start: Date;
  end: Date;

  constructor(json: any) {
    this.id = json.id;
    this.date = json.date;
    this.start = new Date(json.start);
    this.end = new Date(json.end);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { BusinessUnitService } from './core/services/business-unit.service';
import { BusinessUnit } from './core/models/BusinessUnit';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  businessUnit: BusinessUnit;
  logo: string;
  currentYear: number;

  constructor(private businessUnitService: BusinessUnitService) {}

  ngOnInit() {
    this.businessUnitService.get().subscribe(res => {
      this.businessUnit = res;
    });
    this.logo = '/pub/businessUnits/logo';
    this.currentYear = new Date().getFullYear();
  }
}

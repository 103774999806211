import { UnitOfMeasure } from './UnitOfMeasure';

export class PropertyTrait {
  id: string;
  name: string;
  unitOfMeasureName: string;
  order: number;
  active: boolean;
  records: number;
  unitOfMeasure: UnitOfMeasure;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.unitOfMeasureName = json.unitOfMeasureName;
    this.order = json.order;
    this.active = json.active;
    this.records = json.records;
    this.unitOfMeasure = json.unitOfMeasure != null ? new UnitOfMeasure(json.unitOfMeasure) : null;
  }
}

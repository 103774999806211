import { NgModule } from '@angular/core';

import { FormatPhoneNumberPipe } from './format-phone-number.pipe';


@NgModule({
    declarations: [
        FormatPhoneNumberPipe,
    ],
    exports: [
        FormatPhoneNumberPipe,
    ],
    providers: [
        FormatPhoneNumberPipe,
    ]
})
export class SharedPipesModule{}
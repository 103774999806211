import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { EnvironmentService } from './core/services/environment.service';
import { AppComponent } from './app.component';
import { OnlineBookingComponent } from './online-booking/online-booking.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WwsHttpInterceptor } from './core/WwsHttpInterceptor';
import { AccessTokenAuthGuard } from './core/AccessTokenAuthGuard';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxLoadingModule } from 'ngx-loading';
import { OnlineBookingWizardComponent } from './online-booking/online-booking-wizard/online-booking-wizard.component';
import { NotInServiceAreaComponent } from './online-booking/not-in-service-area/not-in-service-area.component';
import { AddressComponent } from './core/address/address.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddressSelectorComponent } from './core/address/address-selector/address-selector.component';
import { OnlineBookingCompleteComponent } from './online-booking/online-booking-complete/online-booking-complete.component';
import { StateAbbreviationTypeaheadComponent } from './core/address/state-abbreviation-typeahead/state-abbreviation-typeahead.component';
import { SecuredImageComponent } from './core/components/secured-image/secured-image.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { OtherIssueSubmittedComponent } from './online-booking/other-issue-submitted/other-issue-submitted.component';
import { SharedDirectivesModule }  from './core/directives/shared-directives.module';
import { SharedPipesModule }  from './core/pipes/shared-pipes.module';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'online-booking',
    pathMatch: 'full'
  },
  {
    path: 'online-booking',
    component: OnlineBookingComponent,
    canActivate: [AccessTokenAuthGuard],
    children: [{ path: '', redirectTo: 'wizard', pathMatch: 'full' }, { path: 'wizard', component: OnlineBookingWizardComponent }, { path: 'not-in-service-area', component: NotInServiceAreaComponent }, { path: 'other-issue-submitted', component: OtherIssueSubmittedComponent }, { path: 'complete', component: OnlineBookingCompleteComponent }]
  }
];

const appInitializerFn = (environment: EnvironmentService) => {
  return () => {
    return environment.loadEnvironment();
  };
};

@NgModule({
  declarations: [AppComponent, OnlineBookingComponent, OnlineBookingWizardComponent, NotInServiceAreaComponent, AddressComponent, AddressSelectorComponent, OnlineBookingCompleteComponent, StateAbbreviationTypeaheadComponent, SecuredImageComponent, OtherIssueSubmittedComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ArchwizardModule,
    NgbModule,
    NgxLoadingModule.forRoot({}),
    RecaptchaModule.forRoot({
      siteKey: '6LeMkHUUAAAAAGnDf9Lo3Negm4MV6ZhIkxwNs2zM'
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    SharedPipesModule,
    SharedDirectivesModule,
  ],
  entryComponents: [AddressSelectorComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WwsHttpInterceptor,
      multi: true
    },
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [EnvironmentService]
    },
    AccessTokenAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

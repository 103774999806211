import { Component, Input } from '@angular/core';
import { GeocodeAddressInput } from '../models/GeocodeAddressInput';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements ControlValueAccessor {
  @Input()
  geocodeAddressInput: GeocodeAddressInput;

  writeValue(obj: any): void {
    return;
  }
  registerOnChange(fn: any): void {
    return;
  }
  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  constructor() {}
}

import { GeocodedAddress } from './../../models/GeocodedAddress';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss']
})
export class AddressSelectorComponent {
  @Input()
  addresses: Array<GeocodedAddress>;
  selectedAddress: GeocodedAddress;

  constructor(public activeModal: NgbActiveModal) {}

  addressClicked(address: GeocodedAddress) {
    this.selectedAddress = address;
  }

  isSelected(a: GeocodedAddress) {
    if (this.selectedAddress == null) {
      return false;
    }
    return a.latitude === this.selectedAddress.latitude && a.longitude === this.selectedAddress.longitude;
  }
}

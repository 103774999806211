export class Address {
  lineOne: string;
  lineTwo: string;
  city: string;
  state: string;
  stateAbbreviation: string;
  postalCode: string;
  country: string;
  countryCode: string;
  timeZoneId: string;

  constructor(json: any) {
    this.lineOne = json.lineOne;
    this.lineTwo = json.lineTwo;
    this.city = json.city;
    this.state = json.state;
    this.stateAbbreviation = json.stateAbbreviation;
    this.postalCode = json.postalCode;
    this.country = json.country;
    this.countryCode = json.countryCode;
    this.timeZoneId = json.timeZoneId;
  }
}

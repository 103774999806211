import { GeocodedAddress } from './../../core/models/GeocodedAddress';

export class OnlineBookingNewAccountInput {
  firstName: string;
  lastName: string;
  company: string;
  emailAddress: string;
  phoneNumber: string;
  address: GeocodedAddress;
  skipExistingAccountValidation: boolean;
  skipServiceTerritoryValidation: boolean;
  followUpReason: string;
  businessUnitId: string;
  constructor() {
    this.address = new GeocodedAddress({});
  }
}

import { TokenService } from './services/token.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class AccessTokenAuthGuard implements CanActivate {
  constructor(private tokenService: TokenService) {}

  canActivate() {
    const token = this.findAccessToken();
    if (token == null) {
      return false;
    }
    localStorage.setItem('accessToken', token);
    return this.tokenService.validate(token);
  }

  findAccessToken() {
    const items = window.location.search.replace('?', '').split('&');
    for (let x = 0; x < items.length; x++) {
      const split = items[x].split('=');
      if (split[0] === 'accessToken') {
        return split[1];
      }
    }
  }
}

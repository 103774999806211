import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BusinessUnit } from '../models/BusinessUnit';
import { map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {
  constructor(private http: HttpClient) {}

  get(): Observable<BusinessUnit> {
    return this.http.get<BusinessUnit>('/pub/businessUnits').pipe(map(bu => new BusinessUnit(bu), retry(1)));
  }

  getLogo(): Observable<any> {
    return this.http.get<any>('/pub/businessUnits/logo');
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';

export class PhoneValidator {
  // Inspired on: https://github.com/yuyang041060120/ng2-validation/blob/master/src/equal-to/validator.ts
  static isValid = (): ValidatorFn => {
    return (phoneControl: AbstractControl): { [key: string]: boolean } => {
      if (phoneControl.value) {
        try {
          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const phoneNumber = '' + phoneControl.value + '';
          const result = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
          const isValidNumber = phoneUtil.isValidNumber(result);

          if (isValidNumber) {
            return undefined;
          }
        } catch (e) {
          // console.log(e);
          return {
            validCountryPhone: true
          };
        }

        return {
          validCountryPhone: true
        };
      } else {
        return undefined;
      }
    };
    // tslint:disable-next-line:semicolon
  };
}

import { Phone } from './Phone';

export class BusinessUnit {
  name: string;
  phone: Phone;
  email: string;
  logoImageId: string;

  constructor(json: any) {
    this.name = json.name;
    this.phone = json.phone;
    this.email = json.email;
    this.logoImageId = json.logoImageId;
  }
}

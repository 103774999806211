import { Address } from '../../core/models/Address';

export class OnlineBookingBillingInput {
  billingLocation: BillingLocationInput;
  paymentAccount: PaymentAccountInput;

  constructor() {
    this.billingLocation = new BillingLocationInput();
  }
}

export class BillingLocationInput {
  billingLocationId: string;
  accountId: string;
  businessUnitId: string;
  defaultContactId: string;
  name: string;
  number: number;
  active: boolean;
  address: Address;
  isDefaultBillingLocation: boolean;
  billingMemo: string;

  constructor() {
    this.active = true;
    this.address = new Address({});
  }
}

export class PaymentAccountInput {
  id: string;
  accountType: string;
  accountId: string;
  businessUnitId: string;
  friendly: string;
  accountNumber: string;
  routingNumber: string;
  nameOnCard: string;
  cardType: string;
  month: string;
  year: string;
  processorPaymentAccountId: string;
}

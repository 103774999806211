// ./app/shared/shared.module.ts
import { NgModule } from '@angular/core';

import { PhoneNumberFormatterDirective } from './phone-number-formatter.directive';


@NgModule({
    declarations: [
        PhoneNumberFormatterDirective,
    ],
    exports: [
        PhoneNumberFormatterDirective,
    ]
})
export class SharedDirectivesModule{}
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

@Injectable()
export class EnvironmentService {
    private environment;
    private httpClient: HttpClient;

    constructor( handler: HttpBackend) { 
        this.httpClient = new HttpClient(handler);
    }
    
    loadEnvironment() {
        
        return this.httpClient.get('/environment.json')
        .toPromise()
        .then(data => {
            this.environment = data;
        })
    }

    get variables() {
        return this.environment;
    }
}
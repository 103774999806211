import { BusinessUnitService } from './../../core/services/business-unit.service';
import { Component, OnInit } from '@angular/core';
import { BusinessUnit } from '../../core/models/BusinessUnit';

@Component({
  selector: 'app-online-booking-complete',
  templateUrl: './online-booking-complete.component.html',
  styleUrls: ['./online-booking-complete.component.scss']
})
export class OnlineBookingCompleteComponent implements OnInit {
  businessUnit: BusinessUnit;
  constructor(private businessUnitService: BusinessUnitService) {}
  ngOnInit() {
    this.businessUnitService.get().subscribe(res => {
      this.businessUnit = res;
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-in-service-area',
  templateUrl: './not-in-service-area.component.html',
  styleUrls: ['./not-in-service-area.component.scss']
})
export class NotInServiceAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

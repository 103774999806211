import { GeocodedAddress } from './../models/GeocodedAddress';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeocodeAddressInput } from '../models/GeocodeAddressInput';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  constructor(private http: HttpClient) {}

  geocode(input: GeocodeAddressInput): Observable<Array<GeocodedAddress>> {
    const parameters = new HttpParams()
      .set('address', input.address)
      .set('city', input.city)
      .set('stateAbbreviation', input.stateAbbreviation)
      .set('postalCode', input.postalCode)
      .set('includeTimeZone', 'true');
    return this.http.get<Array<GeocodedAddress>>('/pub/geocode', { params: parameters }).pipe(map(items => items.map(i => new GeocodedAddress(i))));
  }
}

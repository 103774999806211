export class Issue {
  id: string;
  issue: string;
  businessUnitId: string;

  constructor(json: any) {
    this.id = json.id;
    this.issue = json.issue;
    this.businessUnitId = json.businessUnitId;
  }
}

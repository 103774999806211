import { AddressSelectorComponent } from './address-selector/address-selector.component';
import { GeocodeAddressInput } from './../models/GeocodeAddressInput';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeocodedAddress } from '../models/GeocodedAddress';
import { GeocodingService } from '../services/geocoding.service';

@Injectable({
  providedIn: 'root'
})
export class AddressResolverService {
  constructor(private geocodeService: GeocodingService, private modalService: NgbModal) {}

  execute(input: GeocodeAddressInput): Promise<GeocodedAddress> {
    return this.geocodeService
      .geocode(input)
      .toPromise()
      .then(addresses => {
        addresses = addresses.filter(a => a.isValid);
        if (addresses.length === 1) {
          return addresses[0];
        } else if (addresses.length === 0) {
          return null;
        } else {
          const modalRef = this.modalService.open(AddressSelectorComponent, { size: 'lg' });
          modalRef.componentInstance.addresses = addresses;
          return modalRef.result.then((address: GeocodedAddress) => {
            return address;
          });
        }
      });
  }
}

import { Component, OnChanges, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap, map, retry } from 'rxjs/operators';

@Component({
  selector: 'app-secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss']
})
export class SecuredImageComponent implements OnChanges {
  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {}

  @Input()
  private src: string;

  @Input()
  height: number;

  private src$ = new BehaviorSubject(this.src);

  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))),
      retry(1)
    );
  }
}

import { Injectable } from '@angular/core';
import { OnlineBookingServiceInput } from './models/OnlineBookingServiceInput';
import { OnlineBookingNewAccountInput } from './models/OnlineBookingNewAccount';
import { OnlineBookingExistingAccountInput } from './models/OnlineBookingExistingAccount';
import { OnlineBookingBillingInput } from './models/OnlineBookingBillingInput';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Issue } from './models/Issue';
import { ServiceOffering } from './models/ServiceOffering';
import { OnlineBookingWindow, OnlineBookingSchedulingWindow } from './models/OnlineBookingWindow';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlineBookingService {
  routePrefix: string;
  constructor(private http: HttpClient) {
    this.routePrefix = '/pub/onlineBooking/';
  }

  postNewAccount(input: OnlineBookingNewAccountInput): Observable<string> {
    return this.http.post<string>(this.buildUrl('newAccount'), input);
  }

  putNewAccount(id: string, input: OnlineBookingNewAccountInput): Observable<string> {
    return this.http.put<string>(this.buildUrlWithId(id, 'newAccount'), input);
  }

  postExistingAccount(input: OnlineBookingExistingAccountInput): Observable<string> {
    return this.http.post<string>(this.buildUrl('existingAccount'), input);
  }

  putExistingAccount(id: string, input: OnlineBookingExistingAccountInput): Observable<string> {
    return this.http.put<string>(this.buildUrlWithId(id, 'existingAccount'), input);
  }

  postServices(id: string, input: OnlineBookingServiceInput): Observable<any> {
    return this.http.post(this.buildUrlWithId(id, 'service'), input);
  }

  postBilling(id: string, input: OnlineBookingBillingInput): Observable<any> {
    return this.http.post(this.buildUrlWithId(id, 'billing'), input);
  }

  getIssues(): Observable<Array<Issue>> {
    return this.http.get<Array<Issue>>(this.buildUrl('issues')).pipe(map(items => items.map(i => new Issue(i))));
  }

  getServiceOfferings(issueId: string): Observable<Array<ServiceOffering>> {
    const options = { params: new HttpParams().set('issueId', issueId) };
    return this.http.get<Array<ServiceOffering>>(this.buildUrl('serviceOfferings'), options).pipe(map(items => items.map(i => new ServiceOffering(i))));
  }

  getOnlineBookingWindows(): Observable<Array<OnlineBookingWindow>> {
    return this.http.get<Array<OnlineBookingWindow>>(this.buildUrl('windows')).pipe(map(items => items.map(i => new OnlineBookingWindow(i))));
  }

  getOnlineBookingSchedulingWindows(skip: any, take: any): Observable<Array<OnlineBookingSchedulingWindow>> {
    const options = { params: new HttpParams().set('skip', skip).set('take', take) };
    return this.http.get<Array<OnlineBookingSchedulingWindow>>(this.buildUrl('schedulingWindows'), options).pipe(map(items => items.map(i => new OnlineBookingSchedulingWindow(i))));
  }

  private buildUrl(endpoint: string) {
    return this.routePrefix + endpoint;
  }

  private buildUrlWithId(id: string, endpoint: string) {
    return this.buildUrl(id + '/' + endpoint);
  }
}

import { Address } from './Address';

export class GeocodedAddress extends Address {
  latitude: number;
  longitude: number;

  constructor(json: any) {
    super(json);
    this.latitude = json.latitude;
    this.longitude = json.longitude;
  }

  get isValid() {
    return !this.isNullOrEmpty(this.lineOne) && !this.isNullOrEmpty(this.city) && !this.isNullOrEmpty(this.countryCode) && !this.isNullOrEmpty(this.latitude) && !this.isNullOrEmpty(this.longitude) && !this.isNullOrEmpty(this.postalCode) && !this.isNullOrEmpty(this.state) && !this.isNullOrEmpty(this.stateAbbreviation);
  }

  private isNullOrEmpty(value: any) {
    return !value || value === undefined || value === '' || value.length === 0;
  }
}

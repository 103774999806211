import { AddressResolverService } from './../../core/address/address-resolver.service';
import { OnlineBookingBillingInput } from './../models/OnlineBookingBillingInput';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OnlineBookingSchedulingWindow } from '../models/OnlineBookingWindow';
import { ServiceOffering } from '../models/ServiceOffering';
import { Issue } from '../models/Issue';
import { OnlineBookingServiceInput, PropertyTraitValue } from '../models/OnlineBookingServiceInput';
import { OnlineBookingNewAccountInput } from '../models/OnlineBookingNewAccount';
import { GeocodeAddressInput } from '../../core/models/GeocodeAddressInput';
import { WizardComponent } from 'angular-archwizard';
import { OnlineBookingService } from '../online-booking.service';
import { Form, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PhoneValidator } from '../../core/validators/phone.validator';
import { Observable } from 'rxjs';
import { Address } from 'src/app/core/models/Address';
import { EnvironmentService } from 'src/app/core/services/environment.service';

@Component({
  selector: 'app-online-booking-wizard',
  templateUrl: './online-booking-wizard.component.html',
  styleUrls: ['./online-booking-wizard.component.scss']
})
export class OnlineBookingWizardComponent implements OnInit {
  accountInformation: OnlineBookingNewAccountInput;
  geocodeAddressInput: GeocodeAddressInput;
  billingGeocodeAddressInput: GeocodeAddressInput;
  serviceInput: OnlineBookingServiceInput;
  billingInput: OnlineBookingBillingInput;
  issues: Array<Issue>;
  services: Array<ServiceOffering>;
  selectedOnlineBookingWindow: OnlineBookingSchedulingWindow;
  agreementId: string;
  onlineBookingWindows: Array<OnlineBookingSchedulingWindow>;
  newAccountInputForm: Form;
  logo: any;
  loading: boolean;
  loadingMessage: string;
  serviceAddressControl: FormControl;
  useServiceAddressAsBillingAddress: boolean;
  formGroup: FormGroup;
  onlineBookingSchedulingWindowPage: number;
  previousOnlineBookingSchedulingWindowPage: number;
  myRecaptcha: boolean;
  addressNotFound: boolean;

  @ViewChild(WizardComponent)
  wizard: WizardComponent;

  constructor(private onlineBookingService: OnlineBookingService, private addressResolver: AddressResolverService, private router: Router, private environment: EnvironmentService) {
    this.accountInformation = new OnlineBookingNewAccountInput();
    this.accountInformation.skipExistingAccountValidation = true;
    this.geocodeAddressInput = new GeocodeAddressInput();
    this.serviceAddressControl = new FormControl();
    this.billingGeocodeAddressInput = new GeocodeAddressInput();
    this.serviceInput = new OnlineBookingServiceInput();
    this.billingInput = new OnlineBookingBillingInput();
    this.formGroup = new FormGroup({
      firstName: new FormControl('', Validators.compose([this.whiteSpaceValidator])),
      lastName: new FormControl('', Validators.compose([this.whiteSpaceValidator])),
      company: new FormControl('', Validators.compose([this.whiteSpaceValidator])),
      phoneNumber: new FormControl('', Validators.compose([PhoneValidator.isValid()])),
      emailAddress: new FormControl('', Validators.compose([Validators.email, this.whiteSpaceValidator])),
      lineOne: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      city: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      state: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
      postalCode: new FormControl('', Validators.compose([Validators.required, this.whiteSpaceValidator])),
    },
     { validators: Validators.compose([this.firstOrLastNameRequired, this.emailOrPhoneRequired]) }
    );
    this.onlineBookingSchedulingWindowPage = 1;
  }

  captchaValidated() : boolean {
    return this.myRecaptcha === true || this.environment.variables.ENVIRONMENT === 'Testing'
  }

  emailOrPhoneRequired(group : FormGroup) : {[s:string ]: boolean} {
    if (group.controls['phoneNumber'].value || group.controls['emailAddress'].value){
      return null;
    }
    return {oneOfRequired: true};
  }

  firstOrLastNameRequired(group : FormGroup) : {[s:string ]: boolean} {
    if (group.controls['firstName'].value || group.controls['lastName'].value){
      return null;
    }
    return {oneOfRequired: true};
  }

  whiteSpaceValidator(control: FormControl): {[s:string ]: boolean} {
    if(control.value){
      return (control.value.trim().length !== 0) ? null : { 'whitespace': true };
    }
    return null;
  }
 
  ngOnInit() {
    this.onlineBookingService.getIssues().subscribe(res => {
      this.issues = res;
      this.issues.push(
        new Issue({
          id: 'Other',
          issue: 'Other'
        })
      );
    });
    this.getOnlineBookingSchedulingWindows();
  }

  issueClicked(issue: Issue) {
    if (issue.id === this.serviceInput.issueId) {
      return;
    }
    this.serviceInput.issueId = issue.id;
    this.serviceInput.serviceId = null;
    if (issue.id !== 'Other') {
      this.onlineBookingService.getServiceOfferings(issue.id).subscribe(res => (this.services = res));
    }
  }

  serviceClicked(service: ServiceOffering) {
    if (this.serviceInput.serviceId === service.id) {
      return;
    }
    this.serviceInput.serviceId = service.id;
    this.serviceInput.propertyTraits = service.propertyTraits.map(pt => PropertyTraitValue.createInstance(pt.propertyTrait));
  }

  onlingBookingWindowClicked(window: OnlineBookingSchedulingWindow) {
    this.selectedOnlineBookingWindow = window;
    this.serviceInput.onlineBookingWindowId = window.id;
    this.serviceInput.effectiveDate = window.date;
  }

  isOnlineBookingWindowSelected(window: OnlineBookingSchedulingWindow) {
    if (this.selectedOnlineBookingWindow == null) {
      return false;
    }
    return this.selectedOnlineBookingWindow.id === window.id && this.selectedOnlineBookingWindow.start === window.start && this.selectedOnlineBookingWindow.end === window.end;
  }

  loadPage(page: number) {
    if (page !== this.previousOnlineBookingSchedulingWindowPage) {
      this.selectedOnlineBookingWindow = null;
      this.serviceInput.onlineBookingWindowId = null;
      this.serviceInput.effectiveDate = null;
      this.previousOnlineBookingSchedulingWindowPage = page;
      this.getOnlineBookingSchedulingWindows();
    }
  }

  getOnlineBookingSchedulingWindows() {
    this.onlineBookingService.getOnlineBookingSchedulingWindows(this.onlineBookingSchedulingWindowPage - 1, 12).subscribe(res => {
      this.onlineBookingWindows = res.sort((x, y) => x.start.getTime() - y.start.getTime());
    });
  }

  saveStepOne() {
    if (!this.captchaValidated()) {
      return;
    }
    if(!this.accountInformation.phoneNumber)
      delete this.accountInformation['phoneNumber'];
    if(!this.accountInformation.emailAddress)
      delete this.accountInformation['emailAddress'];
    this.addressNotFound = false;
    const self = this;
    this.startLoading('Checking your address...');
    this.addressResolver.execute(this.geocodeAddressInput).then(function(address) {
      if (address == null) {
        self.addressNotFound = true;
        self.stopLoading();
        return;
      }
      self.accountInformation.address = address;
      self.saveNewAccount();
    });
  }

  saveNewAccount() {
    this.startLoading('Checking your information...');
    return this.saveAccount().subscribe(
      x => {
        this.agreementId = x;
        this.wizard.navigation.goToNextStep();
        this.stopLoading();
      },
      error => this.handleErrorResponseWhenSavingAccount(error)
    );
  }

  skipServiceTerritoryValidationAndSave() {
    this.accountInformation.skipServiceTerritoryValidation = true;
    this.accountInformation.followUpReason = "Customer's address is not within our service territory";
    this.saveAccount().subscribe(x => x);
  }

  private saveAccount(): Observable<string> {
    if (this.agreementId == null) {
      return this.onlineBookingService.postNewAccount(this.accountInformation);
    } else {
      return this.onlineBookingService.putNewAccount(this.agreementId, this.accountInformation);
    }
  }

  private handleErrorResponseWhenSavingAccount(error: any) {
    this.stopLoading();
    if (error.status === 400) {
      this.skipServiceTerritoryValidationAndSave();
      this.router.navigateByUrl('/online-booking/not-in-service-area');
    }
    if (error.status === 409) {
      // Account match
    }
  }

  submitOtherIssue() {
    this.startLoading('Saving follow up reason...');
    this.saveAccount().subscribe(x => {
      this.stopLoading();
      this.router.navigateByUrl('/online-booking/other-issue-submitted');
    });
  }

  saveServices() {
    this.startLoading('Confirming your appointment...');
    return this.onlineBookingService.postServices(this.agreementId, this.serviceInput).subscribe(x => {
      this.stopLoading();
      this.wizard.navigation.goToNextStep();
    });
  }

  complete() {
    this.startLoading('Checking your billing address...');
    if (this.useServiceAddressAsBillingAddress === true) {
      this.billingInput.billingLocation.address = this.accountInformation.address;
      this.saveBilling();
      return;
    }

    const self = this;
    this.addressResolver.execute(this.billingGeocodeAddressInput).then(function(address) {
      if (address == null) {
        self.stopLoading();
        return;
      }
      self.billingInput.billingLocation.address = address;
      self.saveBilling();
    });
  }

  saveBilling() {
    this.onlineBookingService.postBilling(this.agreementId, this.billingInput).subscribe(res => {
      this.stopLoading();
      this.router.navigateByUrl('/online-booking/complete');
    });
  }

  useServiceAddressAsBillingAddressClicked() {
    if (this.useServiceAddressAsBillingAddress) {
      this.billingInput.billingLocation.address = this.accountInformation.address;
      this.billingGeocodeAddressInput.address = this.accountInformation.address.lineOne;
      this.billingGeocodeAddressInput.city = this.accountInformation.address.city;
      this.billingGeocodeAddressInput.stateAbbreviation = this.accountInformation.address.stateAbbreviation;
      this.billingGeocodeAddressInput.postalCode = this.accountInformation.address.postalCode;
    } else {
      this.billingInput.billingLocation.address = new Address({});
      this.billingGeocodeAddressInput = new GeocodeAddressInput();
    }
  }

  private startLoading(message: string) {
    this.loading = true;
    console.log(message);
    this.loadingMessage = message;
  }

  private stopLoading() {
    this.loading = false;
    this.loadingMessage = null;
  }
}

import { PropertyTrait } from '../../core/models/PropertyTrait';
import { Injectable } from '@angular/core';

export class OnlineBookingServiceInput {
  issueId: string;
  serviceId: string;
  effectiveDate: string;
  onlineBookingWindowId: string;
  propertyTraits: Array<PropertyTraitValue>;
}

@Injectable()
export class PropertyTraitValue {
  agreementServiceId: string;
  amount: number;
  propertyTraitId: string;
  propertyTrait: PropertyTrait;

  static createInstance(pt: PropertyTrait): PropertyTraitValue {
    const v = new PropertyTraitValue();
    v.propertyTrait = pt;
    v.propertyTraitId = pt.id;
    return v;
  }
}

import { Directive, ElementRef, HostListener, EventEmitter, OnInit, Output } from '@angular/core';
import { FormatPhoneNumberPipe } from '../pipes/format-phone-number.pipe';

// Directive decorator
@Directive({ 
    selector: '[phoneNumberFormatter]',
})
// Directive class
export class PhoneNumberFormatterDirective implements OnInit {

    private el: HTMLInputElement;
    @Output() ngModelChange = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private formatter: FormatPhoneNumberPipe,
        ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.el.value = this.format(this.el.value);
    }


    @HostListener("blur", ["$event.target.value"])
    onBlur(value) {
        this.el.value = this.format(value);
        this.ngModelChange.emit(this.el.value);
    }

    format(phoneNumber) {
        const sanitize = (phone) => phone.replace(/[^0-9]+/g, '');
        return this.formatter.transform(sanitize(phoneNumber));
    }
}
import { PropertyTrait } from '../../core/models/PropertyTrait';
import { UnitOfMeasure } from '../../core/models/UnitOfMeasure';

export class ServiceOffering {
  id: string;
  name: string;
  description: string;
  duration: number;
  billingType: string;
  unitOfMeasureName: string;
  unitOfMeasureDurationCount: string;
  unitOfMeasureCount: string;
  rate: number;
  propertyTraits: Array<ServiceOfferingPropertyTrait>;
  unitOfMeasure: UnitOfMeasure;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.duration = json.duration;
    this.billingType = json.billingType;
    this.unitOfMeasureName = json.unitOfMeasureName;
    this.unitOfMeasureDurationCount = json.unitOfMeasureDurationCount;
    this.unitOfMeasureCount = json.unitOfMeasureCount;
    this.rate = json.rate;
    this.propertyTraits = json.propertyTraits != null ? json.propertyTraits.map(pt => new ServiceOfferingPropertyTrait(pt)) : new Array<ServiceOfferingPropertyTrait>(); // TODO: map to type
    this.unitOfMeasure = json.unitOfMeasure != null ? new UnitOfMeasure(json.unitOfMeasure) : null;
  }
}

export class ServiceOfferingPropertyTrait {
  id: string;
  propertyTraitId: string;
  serviceOfferingId: string;
  propertyTrait: PropertyTrait;

  constructor(json: any) {
    this.id = json.id;
    this.propertyTraitId = json.propertyTraitId;
    this.serviceOfferingId = json.serviceOfferingId;
    this.propertyTrait = new PropertyTrait(json.propertyTrait);
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import * as libphonenumber from 'google-libphonenumber';
@Pipe({ name: 'formatPhoneNumber'})
export class FormatPhoneNumberPipe implements PipeTransform {

    transform(phoneNumber: number | string): string {
        if (!phoneNumber) { return ''; }
        
        var country = "US";

        var value = phoneNumber.toString().trim().replace(/^\+/, '');
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        const PNF = libphonenumber.PhoneNumberFormat;
        const number = phoneUtil.parseAndKeepRawInput(value, country);
        const result = phoneUtil.format(number, PNF.NATIONAL);
        return result;
    }

  }
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './services/environment.service';

@Injectable()
export class WwsHttpInterceptor implements HttpInterceptor {
  basePath: string;
  constructor(private environment: EnvironmentService) {
    this.basePath = environment.variables ? environment.variables.API_URL : null;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('accessToken') || 'invalid-access-token';
    request = request.clone({
      setHeaders: {
        temporaryAccessToken: token
      },
      url: this.basePath + request.url
    });
    return next.handle(request);
  }
}
